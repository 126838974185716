import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BabyGeneralDetails from "./BabyGeneral";
import MotherProfileDetails from "./MotherProfile";
import GenerateScore from "./GenerateScore";
import BabyHealthParameters from "./BabyHealthParameters";
import BabySemiInvasive from "./SemiInvasive";
import BabyQuickSepsis from "./QuickSepsis";
import BabyCVFunction from "./BabyCardioVascularFunction";
import BabyCNSFunctionDetails from "./BabyCNSFunction";
import BabyGit from "./BabyGit";
import BabyApperance from "./BabyApperance";
import Respiratory from "./Respiratory";
import BabyInvestigation from "./BabyInvestigation";
import BabyAntibiotic from "./BabyAntibiotic";
import FinalDiagnosis from "./FinalDiagnosis";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, Grid, Alert, Snackbar } from "@mui/material";
import BabyBMRNo from "./babybmrno";
import "../styles/DataEntry.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setFormTab,
  getPatientDetails,
  setPatientDetails,
  showBabyRecordForm,
  saveglobalrecordid,
  getReadingid,
  setErrorMessage,
  setsuccessMessage,
  getbmrnlist,
  getPatientBasicDetails,
} from "../reducers/patientDataThunk";

import { scoreTrend } from "../../Dashboard/reducers/Dashboardthunk";
import {
  BABY_FORM_INCOMP,
  MOTHER_FORM_INCOMP,
  BABY_HEALTH_PARAMS_FORM_INCOMP,
  INVASIVE,
  NON_INVASIVE,
  SEMI_INVASIVE,
  QUICK_SEPS,
} from "../utils/mockData";
const NonInvasiveSteps = [
  { label: "Non Invasive Parameters", key: 3, className: "deactivated" },
  { label: "Final Diagnosis", key: 10, className: "purple" },
  { label: "Generate Score", key: 12, className: "purple" },
];
const InvasiveSteps = [
  { label: "Baby Apperance", key: 3, className: "deactivated" },
  { label: "Baby Respiratory Function", key: 4, className: "deactivated" },
  { label: "Baby Cardio Vascular Function", key: 5, className: "deactivated" },
  { label: "Baby CNS Function", key: 6, className: "deactivated" },
  { label: "Baby GI Tract Function", key: 7, className: "deactivated" },
  { label: "Baby Investigation", key: 8, className: "deactivated" },
  { label: "Antibiotic Administration", key: 9, className: "deactivated" },
  { label: "Final Diagnosis", key: 10, className: "purple" },
  { label: "Generate Score", key: 12, className: "purple" },
];

const SemiInvasiveSteps = [
  { label: "Semi Invasive Parameters", key: 3, className: "deactivated" },
  { label: "Final Diagnosis", key: 10, className: "purple" },
  { label: "Generate Score", key: 12, className: "purple" },
];
const QuickSepsisSteps = [
  { label: "Baby Quick Sepsis", key: 3, className: "deactivated" },
  { label: "Final Diagnosis", key: 10, className: "purple" },
  { label: "Generate Score", key: 12, className: "purple" },
];
export default function DataEntry(props) {
  const location = useLocation();
  // console.log("location", location);
  const user = useSelector((state) => state.user);
  const [post, setPost] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [bmrnOptions, setBmrnOptions] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(3);
  const patient = useSelector((state) => state.patient);
  const readingdata = useSelector((state) => state.readingdata);
  let entityTypeSel1 = patient.bmrndata?.entity_type;
  const [triggerChildFunction, setTriggerChildFunction] = React.useState(false);
  const [nextTab, setNextTab] = React.useState(false);

  const hospitalId = user.userProfile.hospital_id;
  const studyId = patient.bmrndata?.study_id;
  const user_id = user.userProfile.user_id;

  const hospitalBranchId = user.branchdata.branch_id;

  const [bmrnSelected, setBmrnSelected] = React.useState(patient.bmrndata);
  /* const [entityTypeSel, setEntityTypeSel] = React.useState(
    entityTypeSel1
  ); */

  const [steps, setSteps] = React.useState([]);

  const dispatch = useDispatch();
  const [TrendView, setTrendView] = useState([]);

  useEffect(() => {
    let tempSteps = [];
    if (patient.bmrndata?.entity_type) {
      entityTypeSel1 = patient.bmrndata?.entity_type;
      if (patient.bmrndata?.entity_type === INVASIVE) {
        tempSteps = InvasiveSteps;
      } else if (patient.bmrndata?.entity_type === NON_INVASIVE) {
        tempSteps = NonInvasiveSteps;
      } else if (patient.bmrndata?.entity_type === SEMI_INVASIVE) {
        tempSteps = SemiInvasiveSteps;
      } else if (patient.bmrndata?.entity_type === QUICK_SEPS) {
        tempSteps = QuickSepsisSteps;
      }

      if (!patient.checkedFinalDiagnosis) {
        setSteps(tempSteps.filter((s) => s.key !== 10));
      } else {
        setSteps(tempSteps);
      }
    }
  }, [patient.bmrndata?.entity_type]);

  useEffect(() => {
    const hospitalId = user.userProfile.hospital_id;
    const req = { hospitalId, hospitalBranchId };
    dispatch(getPatientDetails(req));

    let dataEntryData = sessionStorage.getItem("dataEntry");
    console.log('jjjjjjjjjjjjj',dataEntryData)
    if (dataEntryData) {
      dataEntryData = JSON.parse(dataEntryData);
      if (dataEntryData.baby_medical_record_number) {
        dispatch(getPatientBasicDetails(dataEntryData));
        if (dataEntryData.activeTab) {
          dispatch(setFormTab(dataEntryData.activeTab));
        }
      }
    }
  }, []);
  useEffect(() => {
    if (hospitalBranchId) {
      setBmrnOptions([
        { baby_medical_record_number: "+ Add BMRN" },
        ...patient.bmrnList,
      ]);
    } else {
      setBmrnOptions([{ baby_medical_record_number: "" }, ...patient.bmrnList]);
    }
  }, [patient.bmrnList]);

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  useEffect(() => {
    let temp = [];
    if (entityTypeSel1 === INVASIVE) {
      temp = InvasiveSteps;
    } else if (entityTypeSel1 === NON_INVASIVE) {
      temp = NonInvasiveSteps;
    } else if (entityTypeSel1 === QUICK_SEPS) {
      temp = QuickSepsisSteps;
    } else if (entityTypeSel1 === SEMI_INVASIVE) {
      temp = SemiInvasiveSteps;
    }
    if (!patient.checkedFinalDiagnosis) {
      setSteps(temp.filter((s) => s.key !== 10));
    } else {
      setSteps(temp);
    }
  }, [patient.checkedFinalDiagnosis]);

  if (patient.Selectedreadingid) {
    // console.log("Selectedreadingid", patient.Selectedreadingid);
  }

  useEffect(() => {
    if ((patient.formTab && patient.formTab < 4) || patient.formTab > 0) {
      setActiveTab(patient.formTab);
    }
  }, [patient.formTab]);

  useEffect(() => {
    setBmrnSelected(patient.bmrndata);
  }, [patient.bmrndata]);

  useEffect(() => {
    if (location.state && location.state.fromDashboard) {
      setActiveTab(12);
    }
  }, []);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const showForm = () => {
    if (
      !bmrnSelected ||
      !bmrnSelected.patient_basic_infos_id ||
      !entityTypeSel1
    ) {
      return;
    }
    dispatch(showBabyRecordForm({ bmrnSelected, entityTypeSel1 }));

    const studyId = bmrnSelected.patient_basic_infos_id;
    const recordId = "R3";
    const req = { studyId, recordId, entityTypeSel1 };
    //dispatch(getNewReading(studyId));
    dispatch(saveglobalrecordid(req));
    dispatch(scoreTrend(studyId))
      .unwrap()
      .then((resdata1) => {
        const trendscore = resdata1.response;
        setTrendView(trendscore);
      });
  };

  /* const onBmrnSelected = (e, value) => {
    if (value && !value.patient_basic_infos_id) {
      openDialog();
    } else {
      setBmrnSelected(value);
    }
  };
   const onEntityTypeChange = (e, value) => {
    setEntityTypeSel(value);
    if (value === INVASIVE) {
      setSteps(InvasiveSteps);
    } else {
      setSteps(NonInvasiveSteps);
    }
  }; */
  const editBmrnData = () => {
    openDialog();
  };

  const validateAndUpdateTab = (tabKey) => {
    setNextTab(tabKey);
    setTriggerChildFunction(true);
    setTimeout(() => {
      setTriggerChildFunction(false);
      if (patient.tabKey < 12 || patient.tabKey > 0) {
        setActiveTab(patient.tabKey);
        updateSessionData(patient.tabKey);
      }
      if (tabKey === 12) {
        updateSessionData(tabKey);
        if (patient.isBabyHealthParameters) {
          dispatch(setFormTab(tabKey));
        }
      } else {
        updateSessionData(tabKey);
        dispatch(setFormTab(tabKey));
      }
    });
  };

  const updateSessionData = (tab) => {
    sessionStorage.setItem(
      "dataEntry",
      JSON.stringify({
        baby_medical_record_number: patient.bmrndata.baby_medical_record_number,
        study_id: patient.bmrndata.study_id,
        reading: patient.bmrndata.reading_id,
        activeTab: tab,
      })
    );
  };

  return (
    <React.Fragment>
      <BabyBMRNo editBmrnData={editBmrnData} />
      <div className="dataoutlet1">
        <Grid container spacing={2}>
          {activeTab < 1 && (
            <Grid item xs={12} sm={9}>
              <Card variant="none">
                <CardContent></CardContent>
              </Card>
            </Grid>
          )}

          {activeTab < 12 && activeTab >= 1 && (
            <Grid item xs={12} sm={9}>
              {activeTab === 3 && entityTypeSel1 === NON_INVASIVE && (
                <BabyHealthParameters
                  triggerChildFunction={triggerChildFunction}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 3 && entityTypeSel1 === INVASIVE && (
                <BabyApperance
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 4 && entityTypeSel1 === INVASIVE && (
                <Respiratory
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 5 && entityTypeSel1 === INVASIVE && (
                <BabyCVFunction
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 6 && entityTypeSel1 === INVASIVE && (
                <BabyCNSFunctionDetails
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 7 && entityTypeSel1 === INVASIVE && (
                <BabyGit
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 8 && entityTypeSel1 === INVASIVE && (
                <BabyInvestigation
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 9 && entityTypeSel1 === INVASIVE && (
                <BabyAntibiotic
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
              {activeTab === 10 && patient.checkedFinalDiagnosis && (
                <FinalDiagnosis
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}

              {activeTab === 3 && entityTypeSel1 === SEMI_INVASIVE && (
                <BabySemiInvasive
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}

              {activeTab === 3 && entityTypeSel1 === QUICK_SEPS && (
                <BabyQuickSepsis
                  triggerChildFunction={triggerChildFunction}
                  updateSessionData={updateSessionData}
                  nextTab={nextTab}
                />
              )}
            </Grid>
          )}
          {activeTab < 12 && (
            <Grid item xs={12} sm={3}>
              <div className="formSection">
                {/* {activeTab && ( */}
                <Card variant="none">
                  <CardContent>
                    <div style={{ textAlign: "center" }}>
                      <Grid container spacing={2}>
                        {steps.map((step) => {
                          return (
                            <Grid item xs={12}>
                              <Button
                                className={step.className}
                                variant={
                                  activeTab === step.key
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => validateAndUpdateTab(step.key)}
                              >
                                {step.label}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
                {/* )} */}
              </div>
            </Grid>
          )}
          {activeTab && activeTab >= 12 && (
            <Grid item xs={12} sm={12}>
              {activeTab === 12 && (
                <span>
                  {location.state && location.state.fromDashboard ? (
                    <GenerateScore
                      fromDashboard={location.state.fromDashboard}
                      score={location.state.score}
                      explanation={location.state.explanation}
                    />
                  ) : (
                    <GenerateScore />
                  )}{" "}
                </span>
              )}
            </Grid>
          )}
        </Grid>
      </div>

      {patient.errorMessage && (
        <Snackbar
          open={patient.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {patient.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {patient.successMessage && (
        <Snackbar
          open={patient.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {patient.successMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  IconButton,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { CustomPagination } from "../../Dashboard/components/DashboardScreen";
const ALPHA = /^[a-zA-Z ]+$/;
const Numerics = /^[0-9 ]*$/;
const testEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Stack from "@mui/material/Stack";

import { DataGrid } from "@mui/x-data-grid";
import Ward from "./ward/ward";
import CheckIcon from "@mui/icons-material/Check";
import * as _ from "lodash";
import "../../dataEntry/styles/DataEntry.scss";
import {
  getBranchDetail,
  addbranchdetails,
  setAddBranchFormData,
  setErrorMessage,
  setsuccessMessage,
} from "../reducers/branchDataThunk";
import {
  getmapedBranchDetail,
  activateBranch,
  getWardDetail,
  getMappedWardsOfBranch,
} from "../reducers/wardThunk";
import { DATA_FAIL, DATA_SUCCESS } from "../../dataEntry/utils/mockData";
import { WARD_FORM_MOCK, mapData } from "../reducers/wardInitialState";
import { branchdata, BRANCH_FORM_MOCK } from "../reducers/branchInitialState";
import { TELUGU_STATES } from "../utils/mockdata";
import DeleteBranch from "./ward/DeleteBranch";
import AdminEditImg from "../../common/assets/images/components/admineditsvg";
import DeleteImg from "../../common/assets/images/components/Deleteimg";

const Branch = (props) => {
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch);
  const ward = useSelector((state) => state.ward);
  const login = useSelector((state) => state.login);
  const [addBranchData, setAddBranchData] = useState(branch.branchdata);
  const [deleteSelectedBranch, setDelete] = React.useState(false);
  const [SelectedBranch, setSelectedBranch] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [branchMap, setBranchMap] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [allWards, setAllWards] = useState([]);
  const [mappedWards, setMappedWards] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showBranchNameErrorMessage, setShowBranchNameErrorMessage] =
    useState();
  const [showMobileErrorMessage, setShowMobileErrorMessage] = useState();
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState();
  const [showCityErrorMessage, setShowCityErrorMessage] = useState();
  const [showPincodeErrorMessage, setShowPincodeErrorMessage] = useState();
  const [selectedStatus, setSelectedStatus] = React.useState(-1);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  const hospital_id = user.userProfile.hospital_id;

  useEffect(() => {
    //const hospital_id = 162;
    loadAllData();
  }, []);

  const loadAllData = () => {
    dispatch(getmapedBranchDetail(hospital_id));
    dispatch(getWardDetail(hospital_id)).then((data) => {
      setAllWards(data.payload.response);
    });
  };

  useEffect(() => {
    const { branchList } = branch;
    const { branchMaping } = ward;
    const mapping = [];
    if (branchList && branchList.length > 0) {
      for (const branch of branchList) {
        mapping.push({ ...branch, selectedWards: [] });
      }
    }
    if (branchMaping && branchMaping.length > 0) {
      for (const map of mapping) {
        map["selectedWards"] = branchMaping
          .filter((bm) => bm.branch_id === map.hospital_branch_id)
          .map((bm) => bm.ward_id);
      }
    }
    setBranchMap(mapping);
  }, [branch.branchList, ward.branchMaping]);

  useEffect(() => {
    updateFilteredBranches(selectedStatus);
  }, [branchMap]);

  const updateFilteredBranches = (status) => {
    if (status == -1) {
      setFilteredBranches([].concat(branchMap));
    } else {
      setFilteredBranches(
        branchMap.filter((branch) => branch.deleted_flag == status)
      );
    }
  };

  useEffect(() => {
    if (branch.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [branch.errorMessage]);

  const filterByStatus = (status) => {
    setSelectedStatus(status);
    updateFilteredBranches(status);
  };

  useEffect(() => {
    if (branch.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [branch.successMessage]);

  const activateBranchRecord = (params) => {
    dispatch(activateBranch(params.row.hospital_branch_id)).then(() => {
      toast.success("Branch Activated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const req = { hospital_id };
      dispatch(getBranchDetail(req));
    });
  };

  const updateSelectedWards = (event) => {
    let wards = event.target.value.map((data) => {
      return data.ward_name ? data.ward_name : data;
    });
    setMappedWards(
      wards.map((ward) => {
        return allWards[_.findIndex(allWards, { ward_name: ward })];
      })
    );
  };

  const columns = [
    {
      field: "branch_name",
      headerName: <span className="gridheader_font">Branch Name</span>,
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.branch_name}</span>
      ),
    },
    {
      field: "contact_person",
      headerName: <span className="gridheader_font">Contact Person</span>,
      width: 175,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.contact_person}</span>
      ),
    },
    {
      field: "contace_number",
      headerName: <span className="gridheader_font">Contact Number</span>,
      width: 155,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.contace_number}</span>
      ),
    },
    {
      field: "email_id",
      headerName: <span className="gridheader_font">Email Id</span>,
      width: 175,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.email_id}</span>
      ),
    },
    {
      field: "city",
      headerName: <span className="gridheader_font">City</span>,
      width: 155,
      flex: 1,
      renderCell: (params) => <span className="dadata">{params.row.city}</span>,
    },
    {
      field: "wards",
      headerName: <span className="gridheader_font">No Of Wards</span>,
      width: 175,
      renderCell: (params) => (
        <span className="dadata">{params.row.selectedWards.length}</span>
      ),
    },
    {
      field: "deleted_flag",
      headerName: <span className="gridheader_font">Status</span>,
      width: 85,
      type: "boolean",
      renderCell: (params) => (
        <span className="dadata">
          {params.row.deleted_flag ? "Deleted" : "Active"}
        </span>
      ),
    },

    {
      field: "Edit",
      headerName: <span className="gridheader_font">Action</span>,
      width: 280,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleOpen(params)}
          >
            Edit
          </Button>

          {params.row.deleted_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#008000",
                //background: "#F98F60",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateBranchRecord(params)}
            >
              Activate
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => deleteBranchData(params)}
            >
              Delete
            </Button>
          )}
        </strong>
      ),
    },
  ];

  const handleOpen = (params) => {
    dispatch(getMappedWardsOfBranch(params.row.hospital_branch_id)).then(
      (data) => {
        setMappedWards(
          data.payload.response.map((ward) => {
            return {
              ward_id: ward.ward_id,
              ward_name:
                allWards[_.findIndex(allWards, { ward_id: ward.ward_id })]
                  .ward_name,
            };
          })
        );
      }
    );
    const data = branch.branchList.filter(
      (d) => d.hospital_branch_id === params.row.hospital_branch_id
    );
    setAddBranchData(data[0]);
    dispatch(setAddBranchFormData(data[0]));
    setOpen(true);
  };

  const deleteBranchData = (params) => {
    const data = branch.branchList.filter(
      (d) => d.hospital_branch_id === params.row.hospital_branch_id
    );
    setSelectedBranch(data[0]);
    setDelete(true);
  };

  const handleClickOpen = () => {
    setMappedWards([]);
    setAddBranchData(BRANCH_FORM_MOCK);
    dispatch(setAddBranchFormData(BRANCH_FORM_MOCK));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeDialog = () => {
    setDelete(false);
  };

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  useEffect(() => {
    const req = { hospital_id };
    dispatch(getBranchDetail(req));
  }, []);

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;
    const newFormData = { ...addBranchData };
    newFormData[fieldname] = fieldvalue;
    setAddBranchData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    const newFormData = { ...addBranchData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    const req = {
      hospital_id,
      newFormData: {
        ...newFormData,
        wards: mappedWards.map((ward) => ward.ward_id),
      },
    };
    dispatch(addbranchdetails(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.error) {
          toast.error(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          const req = { hospital_id };
          dispatch(getBranchDetail(req));
          loadAllData();
          toast.success(resdata.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setOpen(false);
        }
      })
      .catch((e) => {
        toast.error(resdata.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        //dispatch(setErrorMessage(resdata.message));
      });
  };

  const verifyBranchName = (event) => {
    if (event.target.value.length >= "51" || event.target.value.length <= "2") {
      setShowBranchNameErrorMessage(
        "Branch Name Should be between 3 to 50 Characters"
      );
      setErrorFields(Object.assign({}, errorFields, { branch_name: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { branch_name: false }));
      setShowBranchNameErrorMessage("");
    }
  };

  const verifyContact = (event) => {
    const pincodeRegex = /^[6-9]\d{9}$/;
    if (!pincodeRegex.test(event.target.value)) {
      setShowMobileErrorMessage(
        "Mobile Number Should be 10 Digits & It Should Starts From 6 to 9"
      );
      setErrorFields(Object.assign({}, errorFields, { contace_number: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { contace_number: false }));
      setShowMobileErrorMessage("");
    }
  };

  const verifyPincode = (event) => {
    const pincodeRegex1 = /^[1-9][0-9]{5}$/;
    if (!pincodeRegex1.test(event.target.value)) {
      setShowPincodeErrorMessage("pincode should be 6 Digits");
      setErrorFields(Object.assign({}, errorFields, { pincode: true }));
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { pincode: false }));
      setShowPincodeErrorMessage("");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="branchcontent">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <form id="score-generation">
                  <Grid
                    container
                    style={{
                      borderBottom: "0.8px solid rgba(101, 114, 228, 0.4)",
                    }}
                  >
                    <Grid item xs={12} sm={8} className="header">
                      <h4 className="branch-header">List Of Branches</h4>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <label
                        htmlFor="Sort"
                        className="dataentry_label"
                        style={{
                          display: "block",
                          fontSize: "16px",
                          padding: "0px 20px",
                          marginTop: "0px",
                        }}
                      >
                        Status
                        <Select
                          labelId="Role"
                          id="status"
                          name="status"
                          label=""
                          size="small"
                          className="select_usertype"
                          value={selectedStatus}
                          onChange={(event) => {
                            filterByStatus(event.target.value);
                          }}
                        >
                          <MenuItem value="-1">All</MenuItem>
                          <MenuItem value="0">Active</MenuItem>
                          <MenuItem value="1">Deleted</MenuItem>
                        </Select>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={1} className="header">
                      <Button
                        variant="outlined"
                        onClick={handleClickOpen}
                        className="branch_Add"
                      >
                        + Add Branch
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={12} className="header">
                      <div style={{ height: 550, width: "100%" }}>
                        <DataGrid
                          rows={filteredBranches}
                          columns={columns}
                          pageSize={8}
                          rowsPerPageOptions={[10]}
                          showCellRightBorder={true}
                          showColumnRightBorder={true}
                          getRowId={(row) => row.hospital_branch_id}
                          components={{
                            Footer: CustomPagination,
                            NoRowsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No Branches Found
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No Branches found
                              </Stack>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose} className="dialogWidth">
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "0.8px solid",
              borderColor: "rgba(101, 114, 228, 0.4)",
            }}
          >
            <span>
              <Link
                className="FIle-left"
                onClick={handleClose}
                style={{ marginRight: "20px" }}
              >
                <img
                  src={Back}
                  className="file-img-left"
                  alt=""
                  style={{ width: "12px" }}
                />
              </Link>
              {!addBranchData?.hospital_branch_id ? "Add" : "Update"} Branch
            </span>
            <Link className="FIle-right-extreme" onClick={handleClose}>
              <img src={Close} style={{ marginRight: "2px" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Branch Name"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Branch Name
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="branch_name"
                    name="branch_name"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    required
                    value={
                      addBranchData?.branch_name
                        ? addBranchData?.branch_name
                        : ""
                    }
                    //onChange={handleAddFormChange}
                    error={
                      errorFields.branch_name && showBranchNameErrorMessage
                    }
                    errorText={showBranchNameErrorMessage}
                    helperText={
                      errorFields.branch_name && showBranchNameErrorMessage
                        ? showBranchNameErrorMessage
                        : ""
                    }
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyBranchName(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Contact Person"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Contact Person
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="contact_person"
                    name="contact_person"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    onChange={handleAddFormChange}
                    value={
                      addBranchData.contact_person
                        ? addBranchData.contact_person
                        : ""
                    }
                    required
                    error={errorFields.contact_person && showNameErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showNameErrorMessage}
                    helperText={
                      errorFields.contact_person && showNameErrorMessage
                        ? showNameErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!ALPHA.test(event.key)) {
                        setShowNameErrorMessage(
                          "Only Alphabets and Space are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            contact_person: true,
                          })
                        );
                        event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            contact_person: false,
                          })
                        );
                        setShowNameErrorMessage("");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Phone Number"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Phone Number
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="Contact"
                    name="contace_number"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    //onChange={handleAddFormChange}
                    value={addBranchData.contace_number}
                    required
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyContact(e);
                    }}
                    error={errorFields.contace_number && showMobileErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showMobileErrorMessage}
                    helperText={
                      errorFields.contace_number && showMobileErrorMessage
                        ? showMobileErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowMobileErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            contace_number: true,
                          })
                        );
                      }
                    }}
                    //onBlur={verifyContact}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Email ID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Email Id
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="email_id"
                    name="email_id"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    type="email"
                    onChange={handleAddFormChange}
                    value={addBranchData.email_id}
                    required
                    error={errorFields.email_id && showEmailErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showEmailErrorMessage}
                    helperText={
                      errorFields.email_id && showEmailErrorMessage
                        ? showEmailErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!testEmail.test(event.target.value)) {
                        setShowEmailErrorMessage("Please Enter Valid Email Id");
                        setErrorFields(
                          Object.assign({}, errorFields, { email_id: true })
                        );

                        //event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            email_id: false,
                          })
                        );
                        setShowEmailErrorMessage("");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <label
                    htmlFor="Address"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Address
                  </label>
                  <TextField
                    id="address"
                    name="address"
                    label=""
                    variant="outlined"
                    className="dataentry_input_add"
                    size="small"
                    onChange={handleAddFormChange}
                    value={addBranchData.address ? addBranchData.address : ""}
                    InputProps={{
                      inputProps: {
                        maxLength: 250,
                        minLength: 3,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="City"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    City
                  </label>
                  <TextField
                    id="city"
                    name="city"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    onChange={handleAddFormChange}
                    value={addBranchData.city ? addBranchData.city : ""}
                    error={errorFields.city && showCityErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showCityErrorMessage}
                    helperText={
                      errorFields.city && showCityErrorMessage
                        ? showCityErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!ALPHA.test(event.key)) {
                        setShowCityErrorMessage(
                          "Only Alphabets and Space are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, { city: true })
                        );
                        event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, { city: false })
                        );
                        setShowCityErrorMessage("");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="State"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    State
                  </label>
                  <Select
                    labelId="state"
                    id="state"
                    name="state"
                    label=""
                    onChange={handleAddFormChange}
                    value={addBranchData.state}
                    size="small"
                    className="dataentry_select_edu"
                  >
                    {TELUGU_STATES.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Pincode"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Pincode
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="Pincode"
                    name="pincode"
                    label=""
                    variant="outlined"
                    //className="dataentry_input"

                    className={
                      "dataentry_input " +
                      (errorFields.pincode ? "errorField" : "")
                    }
                    size="small"
                    required
                    //onChange={handleAddFormChange}
                    value={addBranchData.pincode}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyPincode(e);
                    }}
                    error={errorFields.pincode && showPincodeErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showPincodeErrorMessage}
                    helperText={
                      errorFields.pincode && showPincodeErrorMessage
                        ? showPincodeErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        setShowPincodeErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, { pincode: true })
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Wards"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Wards
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <Select
                    // labelId="baby_respiratory_support_if_yes"
                    // id="baby_respiratory_support_if_yes"
                    // className="dataentry_select_edu"
                    style={{ width: "90%" }}
                    name="wards"
                    onChange={updateSelectedWards}
                    multiple
                    size="small"
                    value={mappedWards || []}
                    renderValue={(selected) => {
                      return selected && selected.length
                        ? selected.map((s) => s.ward_name).join(",")
                        : "";
                    }}
                  >
                    {allWards.map((ward) => (
                      <MenuItem key={ward.ward_id} value={ward}>
                        <Checkbox
                          checked={
                            _.findIndex(mappedWards, {
                              ward_id: ward.ward_id,
                            }) > -1
                            // mappedWards?.indexOf(
                            //   ward.ward_id
                            // ) > -1
                          }
                        />
                        <ListItemText primary={ward?.ward_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <div className="formSection">
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      className="back"
                      onClick={handleClose}
                    >
                      Back
                    </Button>
                  </Grid>
                  {!addBranchData?.hospital_branch_id ? (
                    <Grid item xs={12} sm={4}>
                      {isValid ? (
                        <Button
                          variant="contained"
                          className="purple-add"
                          type="submit"
                          onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                          })}
                        >
                          <span>Add Branch</span>
                        </Button>
                      ) : (
                        <Button variant="contained" className="back1" disabled>
                          <span>Add Branch</span>
                        </Button>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={4}>
                      {isValid ? (
                        <Button
                          variant="contained"
                          className="purple-add"
                          type="submit"
                          onClick={handleClick({
                            vertical: "top",
                            horizontal: "center",
                          })}
                        >
                          <span>Update Branch</span>
                        </Button>
                      ) : (
                        <Button variant="contained" className="back1" disabled>
                          <span>Update Branch</span>
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      {branch.errorMessage && (
        <Snackbar
          open={branch.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {branch.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {branch.successMessage && (
        <Snackbar
          open={branch.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {branch.successMessage}
          </Alert>
        </Snackbar>
      )}

      {deleteSelectedBranch && (
        <DeleteBranch
          SelectedBranch={SelectedBranch}
          open={deleteSelectedBranch}
          onClose={closeDialog}
        />
      )}
    </React.Fragment>
  );
};

export default Branch;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import moment from "moment";

import {
  Grid,
  Card,
  CardContent,
  Button,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
} from "@mui/material";

import { Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import "../../dataEntry/styles/DataEntry.scss";
import { Box } from "@mui/system";
import { Chart as ChartJS, registerables } from "chart.js";

import {
  getPatientDetails,
  babyPredictionScore,
} from "../../dataEntry/reducers/patientDataThunk";

import { scoreTrend,babyScoreTrend } from "../reducers/Dashboardthunk";

const chartOptions = {
  /* vAxis: {
    gridlines: {
      color: "none",
    },
  }, */
  // tooltip: { isHtml: true },
  // legend: "none",

  bar: { groupWidth: "30%" },
  hAxis: {
    title: "Date",
    gridLines: {
      display: false,
      color: "none",
    },
  },
  series: {
    0: {
      visibleInLegend: false,
    },
  },
  vAxis: {
    title: "Score",
    viewWindow: {
      min: 0,
    },
    gridlines: {
      color: "none",
    },
  },
};

const ViewTrend = (props) => {
  ChartJS.register(...registerables);
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);

  const hospitalId = user.userProfile.hospital_id;
  const hospitalBranchId = user.branchdata.branch_id;
  const studyId =
    props && props.selectedPatient
      ? props.selectedPatient.study_id
      : patient.bmrndata?.study_id;
  const baby_medical_record_number =
    props && props.selectedPatient
      ? props.selectedPatient.baby_medical_record_number
      : patient.bmrnList?.baby_medical_record_number;
  const motherName =
    props && props.selectedPatient
      ? props.selectedPatient.mother_name
      : patient.bmrnList?.mother_name;

  const [TrendView, setTrendView] = useState([]);
  const [babyScoreTable, setBabyScoreTrend] = useState([]);
  const [chartsData, setChartsData] = useState([]);

  const [bmrnOptions, setBmrnOptions] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dbbranchdata.scoreTrend && dbbranchdata.scoreTrend.length) {
      const data = dbbranchdata.scoreTrend.map((reading) => {
        return { ...reading };
      });
      setBmrnOptions(data.concat([]));
    }
  }, [dbbranchdata.scoreTrend]);

  useEffect(() => {
    dispatch(scoreTrend(studyId))
      .unwrap()
      .then((resdata1) => {
        const trendscore = resdata1.response;
        setTrendView(trendscore);
      });
  }, []);

  useEffect(() => {
    dispatch(babyScoreTrend(studyId))
      .unwrap()
      .then((resdata1) => {
        //const trendScore = resdata1.response;
        setBabyScoreTrend(resdata1.response);
      });
  }, []);

  React.useEffect(() => {
    if (bmrnOptions && bmrnOptions.length) {
      const data = [
        ["Date", "Score", { role: "style" }, { role: "annotation" }],
      ];
      let readings = [].concat(bmrnOptions);
      readings.reverse();
      readings.map((reading) => {
        data.push([
          moment(reading.createdAt).format("DD - MM - YY"),
          reading.score,
          getColorCode(reading.score),
          `R${reading.reading}`,
        ]);
      });

      setChartsData(data);
    }
  }, [bmrnOptions]);

  const closeDialog = (e) => {
    e.preventDefault();
    props.onClose();
  };

  const getColorCode = (score) => {
    // console.log("inside function get color code ");
    // console.log("The score is", score);
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth="70%"
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle
          style={{
            borderBottom: "0.8px solid #f0f1fc",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Link
              to="/"
              className="FIle-left"
              onClick={closeDialog}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            <span>Neonatal Sepsis Risk Score Trend </span>
          </div>
          <div>
            <Link to="/" className="FIle-right-extreme" onClick={closeDialog}>
              <img
                src={Close}
                // className="file-img-right-extreme"
                alt=""
              />
            </Link>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box>
            <form
              id="bmrn-form"
              className="view-trend-container"
              style={{ marginTop: 10 }}
            >
              <div>
                <Grid container className="content-header">
                  <Grid item xs={12} sm={6}>
                    <p className="readingsummary_name">
                      BMRN
                      <span className="summary_data">
                        {baby_medical_record_number}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p className="readingsummary_name">
                      Mother's name
                      <span className="summary_data">{motherName}</span>
                    </p>
                  </Grid>
                </Grid>
                <TableContainer
                  style={{
                    marginTop: "2rem",
                    borderRadius: "0.6rem",
                    border: "1px solid #C1C6F4",
                  }}
                >
                  <Table aria-label="simple table" className="Table">
                    <TableHead className="TableHead">
                      <TableRow className="TableRow">
                        <TableCell align="center" id="table-cell">
                          Reading
                        </TableCell>
                        <TableCell align="center" id="table-cell">
                          Date
                        </TableCell>
                        <TableCell align="center" id="table-cell">
                          Time
                        </TableCell>
                        <TableCell align="center" id="table-cell">
                          Score
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {babyScoreTable.map((sheet) => (
                        <TableRow>
                          <TableCell align="center" id="table-cell">
                            R{sheet.reading}
                          </TableCell>
                          <TableCell align="center" id="table-cell">
                            {moment(sheet.createdAt).format("DD - MM - YY")}
                          </TableCell>
                          <TableCell align="center" id="table-cell">
                            {moment(sheet.createdAt).format("HH:mm:ss")}
                          </TableCell>
                          <TableCell align="center" id="table-cell">
                            {sheet.score ? sheet.score.toFixed(1) : "-"}
                            {/*  {bmrnOptions.score} */}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div
                  style={{
                    border: "0.5px solid #D2D2D2",
                    padding: "0px 10px",
                    marginTop: "10px",
                    borderRadius: "0.6rem",
                  }}
                >
                  <p style={{justifyContent:"center",alignItems:"center"}}>
                      Last 5 Readings Trend
                    </p>
                  <Chart
                    chartType="ColumnChart"
                    options={chartOptions}
                    width="100%"
                    height="300px"
                    data={chartsData}
                  />
                </div>
              </div>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default ViewTrend;

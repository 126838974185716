import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import HelpIcon from "@mui/icons-material/Help";

import {
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  Box,
  Typography,
  StepContext,
  InputAdornment,
} from "@mui/material";
import "../styles/DataEntry.scss";
import BabyBMRNo from "./babybmrno";
import { ToastContainer, toast } from "react-toastify";

import PatientLabels from "./ptdetails1";
import {
  AddBmrn_FORM_INCOMP,
  AddBmrn_FORM_Success,
  ENTITY_TYPES,
} from "../utils/mockData";

import {
  getPatientDetails,
  babyMedicalRecordAdd,
  addNewGlobalRecord,
  getDataEntryScreenByStudyID,
  babydataInfo,
  setSelectedBMRN,
  headerBMRN,
  setsuccessMessage,
  clearBasicInfos,
} from "../reducers/patientDataThunk";
import {
  getBranchDetail,
  getBranchDetailByUser,
} from "../../Dashboard/reducers/Dashboardthunk";

const NewBMRN = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dbbranchdata = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bmrnOptions, setBmrnOptions] = React.useState([]);
  const loggedUserId = user.userProfile?.user_id;
  const hospital_id = user.userProfile.hospital_id;
  const hospitalId = user.userProfile.hospital_id;

  const userdata = sessionStorage.getItem("user");
  const is_admin = userdata ? JSON.parse(userdata).is_admin : false;
  const allowedPermissions = userdata ? JSON.parse(userdata).permissions : {};

  const hospitalBranchId = user?.branchdata?.branch_id
    ? user?.branchdata?.branch_id
    : JSON.parse(userdata).branch_id;

  const [addBMRNData, setaddBMRNData] = useState({});
  const [bmrnType, setBmrnType] = useState();
  const [bmrn, setBmrn] = React.useState(sessionStorage.getItem("NewBmrn"));
  const selbranch = useSelector((state) => state.Reports?.SelectedBranchdata);
  const [branchdata, setbranchdata] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState();
  const [SelectedPatientDetails, setSelectedPatientDetails] = useState([]);
  const [PtDetailsView, setPtDetailsView] = React.useState(false);
  useEffect(() => {
    if (is_admin) {
      setbranchdata(dbbranchdata.branchList?.branches);
    } else {
      setbranchdata(dbbranchdata.branchList);
    }
  }, [dbbranchdata.branchList]);

  useEffect(() => {
    sessionStorage.setItem("dataEntry", JSON.stringify({}));
    setTimeout(() => {
      dispatch(clearBasicInfos());
    }, 500);
  }, []);

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 3000);
    }
  }, [patient.errorMessage]);

  const PatientLabel = (params) => {
    setSelectedPatientDetails({ params });
    setPtDetailsView(true);
  };

  const closeDialog1 = () => {
    setPtDetailsView(false);
  };

  useEffect(() => {
    dispatch(getDataEntryScreenByStudyID({}));
  }, []);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 3000);
    }
  }, [patient.successMessage]);

  useEffect(() => {
    if (bmrn && bmrnOptions.length) {
      const index = _.findIndex(bmrnOptions, {
        baby_medical_record_number: bmrn,
      });
      if (index != -1) {
        onBmrnSelected(null, bmrnOptions[index]);
      }
    }
  }, [bmrn, bmrnOptions]);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
    let bmrnData = Object.assign({}, addBMRNData);
    dispatch(setSelectedBMRN(addBMRNData));
    for (const key in bmrnData) {
      if (!bmrnData[key]) {
        bmrnData[key] = "NA";
      }
    }
    if (addBMRNData?.patient_basic_infos_id) {
      const hospitalBranchId = addBMRNData?.hospitalBranchId;
      const req = {
        hospitalId,
        hospitalBranchId,
        addBMRNData: { ...bmrnData, created_by: loggedUserId },
      };
      dispatch(addNewGlobalRecord(req)).then((resdata) => {
        sessionStorage.setItem(
          "dataEntry",
          JSON.stringify({
            baby_medical_record_number: addBMRNData.baby_medical_record_number,
            study_id: addBMRNData.study_id,
            reading: resdata.payload.response.reading_id,
            activeTab: 3,
          })
        );
        navigate("/dataentry");
      });
    }
    
  else{
    toast.error("Please Select BMRN to view records", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  };
  const handleClick1 = (newState) => () => {
    setState({ open: true, ...newState });
    let bmrnData = addBMRNData;

    if (addBMRNData?.patient_basic_infos_id) {
    const req = bmrnData;
    /* dispatch(babydataInfo(req)).then((resdata) => {
      sessionStorage.setItem(
        "dataEntry",
        JSON.stringify({
          bmrnData:bmrnData,
          baby_medical_record_number: bmrnData.baby_medical_record_number,
          study_id: bmrnData.study_id,
          activeTab: 3,
        })
      ); */
      dispatch(clearBasicInfos()).then(() => {
        setTimeout(() => {
          navigate("/AddBMRN");
        }, 500);
      });
  }
  else{
    toast.error("Please Select BMRN to view records", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  };

  const onBmrnSelected = (e, value) => {
    // if (value && !value.patient_basic_infos_id) {
    //   dispatch(getAddBMRNScreen());
    //   navigate("/AddBMRN");
    // } else {
    dispatch(headerBMRN(value));
    setaddBMRNData(value);
    // }
  };

  useEffect(() => {
    const hospitalId = user.userProfile.hospital_id;
    if (hospitalId) {
      const req = { hospitalId, hospitalBranchId };
      dispatch(getPatientDetails(req));
    }
  }, [user.userProfile]);

  useEffect(() => {
    if (hospitalBranchId) {
      setBmrnOptions([...patient.bmrnList]);
    } else {
      setBmrnOptions([...patient.bmrnList]);
    }
  }, [patient.bmrnList]);

  useEffect(() => {
    if (user?.userProfile?.hospital_id) {
      if (is_admin) {
        const req1 = { userId: user.userProfile?.user_id };
        dispatch(getBranchDetailByUser(req1));
      } else {
        const req = { hospital_id: user.userProfile.hospital_id };
        dispatch(getBranchDetail(req));
      }
    }
  }, [user?.userProfile?.hospital_id]);

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const maxLength = event.target.maxLength;
    const fieldvalue = event.target.value;
    const newFormData = { ...addBMRNData };
    newFormData[fieldname] = fieldvalue;
    setaddBMRNData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    // console.log("Data That the Application is sending is,");
    // console.log(addBMRNData);
    // console.log("About to call Axios API");
    let bmrnData = Object.assign({}, addBMRNData);
    // console.log("About to call Axios API");
    for (const key in bmrnData) {
      if (!bmrnData[key]) {
        bmrnData[key] = "NA";
      }
    }
    const hospitalBranchId = addBMRNData?.hospitalBranchId;
    if (addBMRNData?.patient_basic_infos_id) {
      const req = {
        hospitalId,
        hospitalBranchId,
        addBMRNData: { ...bmrnData, created_by: loggedUserId },
      };
      dispatch(addNewGlobalRecord(req)).then((resdata) => {
        navigate("/dataentry");
      });
    } else {
      const req = {
        hospitalId,
        hospitalBranchId,
        addBMRNData: {
          ...bmrnData,
          baby_medical_record_number: bmrnData.new_baby_medical_record_number,
          created_by: loggedUserId,
        },
      };
      dispatch(babyMedicalRecordAdd(req)).then((resdata) => {
        console.log('kkkkkkkkkk',resdata)
        if(resdata.payload.error){
          toast.error(resdata.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        else{
        sessionStorage.setItem(
          "dataEntry",
          JSON.stringify({
            baby_medical_record_number:
              resdata.payload.response.baby_medical_record_number,
            study_id: resdata.payload.response.id,
            activeTab: 3,
          })
        );
        dispatch(clearBasicInfos()).then(() => {
          setTimeout(() => {
            navigate("/AddBMRN");
          }, 500);
        });
      }
      });
    }
  };
  return (
    <React.Fragment>
      
      <ToastContainer />
      <div style={{ marginTop: "10vh" }} className="new-bmrn">
        {patient?.bmrndata?.baby_medical_record_number &&
          allowedPermissions &&
          allowedPermissions.reading &&
          allowedPermissions.reading.indexOf("R") != -1 && <BabyBMRNo />}
        <div className="datacontent">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Card variant="none">
                <CardContent>
                  <p className="addbmrn-content">
                    Select an existing BMRN, or create one to record a new
                    reading -
                  </p>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="sorting_radio"
                    defaultValue={bmrnType}
                  >
                    <Grid container style={{ minHeight: "400px" }}>
                      <Grid item xs={12} sm={6}>
                        <form>
                          <Grid container>
                            <Grid item xs={12} sm={9}>
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Select an existing BMRN"
                                  value="Select BMRN"
                                  name="bmrn"
                                  id="BMRN"
                                  className="radio_sort"
                                  onChange={(e) => {
                                    setBmrnType(e.target.value);
                                  }}
                                  style={{
                                    marginRight: "2px",
                                    paddingRight: "0px",
                                  }}
                                />
                                {/* <span
                                  title="Baby Medical Record Number"
                                  style={{
                                    marginTop: "12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <HelpIcon />
                                </span> */}
                              </div>
                              <Autocomplete
                                className="dataentry_input"
                                id="baby_medical_record_number"
                                name="baby_medical_record_number"
                                options={bmrnOptions}
                                getOptionLabel={(option) =>
                                  option.baby_medical_record_number || ""
                                }
                                disabled={bmrnType !== "Select BMRN"}
                                onChange={onBmrnSelected}
                                value={
                                  addBMRNData?.baby_medical_record_number
                                    ? addBMRNData
                                    : null
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    id="bmrn"
                                    name="bmrn"
                                    onChange={handleAddFormChange}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          {bmrnType === "Select BMRN" ? (
                            <Grid container style={{ margin: "20px 0px auto" }}>
                              {allowedPermissions &&
                                allowedPermissions.bmrn &&
                                allowedPermissions.bmrn.indexOf("U") != -1 && (
                                  <Grid item xs={12} sm={3}>
                                    <div className="formSection">
                                      <Button
                                        variant="outlined"
                                        className="purple"
                                        disabled={bmrnType !== "Select BMRN"}
                                        onClick={handleClick1({
                                          vertical: "top",
                                          horizontal: "center",
                                        })}
                                      >
                                        View/Edit
                                      </Button>
                                    </div>
                                  </Grid>
                                )}
                              {allowedPermissions &&
                                allowedPermissions.bmrn &&
                                allowedPermissions.bmrn.indexOf("U") != -1 && (
                                  <Grid item xs={12} sm={1}></Grid>
                                )}
                              {allowedPermissions &&
                                allowedPermissions.reading &&
                                allowedPermissions.reading.indexOf("C") !=
                                  -1 && (
                                  <Grid item xs={12} sm={4}>
                                    <div className="formSection">
                                      <Button
                                        variant="outlined"
                                        className="purple"
                                        disabled={bmrnType !== "Select BMRN"}
                                        onClick={handleClick({
                                          vertical: "top",
                                          horizontal: "center",
                                        })}
                                      >
                                        Create New Reading
                                      </Button>
                                    </div>
                                  </Grid>
                                )}
                            </Grid>
                          ) : null}
                        </form>
                      </Grid>
                      {allowedPermissions &&
                        allowedPermissions.bmrn &&
                        allowedPermissions.bmrn.indexOf("C") != -1 && (
                          <Divider orientation="vertical" flexItem>
                            <p className="vertical_or">OR</p>
                          </Divider>
                        )}
                      {allowedPermissions &&
                        allowedPermissions.bmrn &&
                        allowedPermissions.bmrn.indexOf("C") != -1 && (
                          <Grid item xs={12} sm={1}></Grid>
                        )}
                      {allowedPermissions &&
                        allowedPermissions.bmrn &&
                        allowedPermissions.bmrn.indexOf("C") != -1 && (
                          <Grid item xs={12} sm={4}>
                            <form onSubmit={HandleAddFormSubmit}>
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Create a new BMRN"
                                  name="Create BMRN"
                                  value="Create BMRN"
                                  id="BMRN1"
                                  className="radio_sort"
                                  onChange={(e) => {
                                    setBmrnType(e.target.value);
                                  }}
                                  style={{
                                    display: "block",
                                    padding: "5px 0px 5px 0px",
                                    fontSize: "12px",
                                    marginRight: "2px",
                                  }}
                                />
                                {/* <span
                                  title="Baby Medical Record Number"
                                  style={{
                                    marginTop: "12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <HelpIcon />
                                </span> */}
                              </div>
                              <TextField
                                id="new_baby_medical_record_number"
                                name="new_baby_medical_record_number"
                                label=""
                                variant="outlined"
                                className="dataentry_input"
                                size="small"
                                style={{ width: "100%" }}
                                disabled={bmrnType !== "Create BMRN"}
                                onChange={handleAddFormChange}
                                InputProps={{
                                  inputProps: {
                                    maxLength: 50,
                                    minLength: 3,
                                  },
                                }}
                              />
                              <div className="formSection">
                                <label
                                  htmlFor="Entry Type"
                                  className="formsection_label"
                                  style={{
                                    display: "block",
                                    padding: "5px 0px 5px 0px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Select Branch
                                </label>
                                <Select
                                  labelId="Bramch"
                                  id="hospitalBranchId"
                                  name="hospitalBranchId"
                                  label=""
                                  size="small"
                                  style={{ width: "100%", textAlign: "left" }}
                                  className="dataentry_select_edu"
                                  value={addBMRNData?.hospitalBranchId}
                                  onChange={handleAddFormChange}
                                  disabled={bmrnType !== "Create BMRN"}
                                >
                                  {branchdata && branchdata.length
                                    ? branchdata.map((num) => (
                                        <MenuItem
                                          value={num.hospital_branch_id}
                                        >
                                          {num.branch_name}
                                        </MenuItem>
                                      ))
                                    : undefined}
                                </Select>
                              </div>

                              <div className="formSection">
                                <label
                                  htmlFor="Entry Type"
                                  className="formsection_label"
                                  style={{
                                    display: "block",
                                    padding: "5px 0px 5px 0px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Entry Type
                                </label>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  value={addBMRNData?.entityTypeSel}
                                  name="entityTypeSel"
                                  id="entityTypeSel"
                                  className="entrytype_radio"
                                  onChange={handleAddFormChange}
                                >
                                  {ENTITY_TYPES.map((type) => (
                                    <span style={{ textAlign: "left" }}>
                                      <FormControlLabel
                                        value={type}
                                        control={<Radio />}
                                        label={type}
                                        disabled={!addBMRNData?.hospitalBranchId}
                                      />
                                      <span
                                        style={{
                                          marginTop: "12px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => PatientLabel(type)}
                                        //onClick={(type)}
                                      >
                                        <HelpIcon />
                                      </span>
                                    </span>
                                  ))}
                                </RadioGroup>
                              </div>
                              <Grid container style={{ minHeight: "80px" }}>
                                {bmrnType === "Create BMRN"  && addBMRNData?.entityTypeSel ? (
                                  <Grid item xs={12} sm={6}>
                                    <div className="formSection">
                                      
                                      <Button
                                        variant="outlined"
                                        className="purple"
                                        type="submit"
                                        disabled={bmrnType !== "Create BMRN"}
                                        
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </form>
                          </Grid>
                        )}
                    </Grid>
                  </RadioGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {PtDetailsView && (
            <PatientLabels
              SelectedPatientDetails={SelectedPatientDetails}
              open={PtDetailsView}
              onClose={closeDialog1}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewBMRN;

import React, { useEffect } from "react";
// import { Document, Page } from "@react-pdf/renderer";
const NotificationView = (props) => {
  const data = props.data;
  const [pdfLink, setPdfLink] = React.useState("");
  useEffect(() => {
    if (data !== "null") {
      const uint8Array = new Uint8Array(data);
      const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfLink(pdfUrl);
    } else {
    }
  }, [data]);

  // Function to download the PDF
  const downloadPdf = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfLink;
    downloadLink.download = "file.pdf";
    downloadLink.click();
  };

  return (
    <>
      <div style={{height:'80vh' }}>    
        <div>        
          {/* <button onClick={() => props.action(false)}>Close</button> */}
          <div wrap style={{ marginTop: 30 }}>
            <iframe
              src={pdfLink}
              style={{ width: "100%", height: "73vh" }}
              title="PDF Viewer"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationView;

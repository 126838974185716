import { createAsyncThunk } from "@reduxjs/toolkit";
import PatientDataService from "../../api/apiCommonService";

export const getBranchDetail = createAsyncThunk(
  "hospitalBranchFetch",
  async (req) => {
    const res = await PatientDataService.getBranchDetail(req);
    return res.data;
  }
);

export const getBranchDetailByUser = createAsyncThunk(
  "hospitalBranchFetchByUser",
  async (req) => {
    const res = await PatientDataService.getBranchDetailByUser(req);
    return res.data;
  }
);

export const getWardDetailbyBranch = createAsyncThunk(
  "getWardDetailbyBranch",
  async (req) => {
    const res = await PatientDataService.getWardDetailbyBranch(req);
    return res.data;
  }
);
export const getAdminDetailByBranch = createAsyncThunk(
  "getAdminDetailByBranch",
  async (req1) => {
    const res = await PatientDataService.getAdminDetailByBranch(req1);
    return res.data;
  }
);

export const scoreTrend = createAsyncThunk("scoreTrend", async (studyId) => {
  const res = await PatientDataService.scoreTrend(studyId);
  return res.data;
});

export const babyScoreTrend = createAsyncThunk("babyScoreTrend", async (studyId) => {
  const res = await PatientDataService.scoreTrendTable(studyId);
  return res.data;
});


export const getdataentryscreen = createAsyncThunk(
  "getNewBmrn",
  async (bmrn) => {
    sessionStorage.setItem("NewBmrn", bmrn);
    sessionStorage.removeItem("ExistBmrn");
    sessionStorage.removeItem("BmrnScreen");
  }
);
export const getAddBMRNScreen = createAsyncThunk(
  "getAddBMRNScreen",
  async () => {
    sessionStorage.setItem("BmrnScreen", "BmrnScreen");
    sessionStorage.removeItem("ExistBmrn");
    sessionStorage.removeItem("NewBmrn");
  }
);

export const getdropinion = createAsyncThunk("getdropinion", async (req) => {
  const res = await PatientDataService.getdropinion(req);
  return res.data;
});
export const getOpinionByRefDr = createAsyncThunk(
  "getOpinionByRefDr",
  async (req) => {
    const res = await PatientDataService.getOpinionByRefDr(req);
    return res.data;
  }
);

export const getReadingIdByRefDoctor = createAsyncThunk(
  "getReadingIdByRefDoctor",
  async (req) => {
    const res = await PatientDataService.getReadingIdByRefDoctor(req);
    return res.data;
  }
);

export const getRefDoctorsByBmrn = createAsyncThunk(
  "getRefDoctorsByBmrn",
  async (req) => {
    const res = await PatientDataService.getRefDoctorsByBmrn(req);
    return res.data;
  }
);

export const deleterecord = createAsyncThunk(
  "deleterecord",
  async (studyid) => {
    const res = await PatientDataService.deleterecord(studyid);
    return res.data;
  }
);

export const updateDashboardBranch = createAsyncThunk(
  "updateDashboardBranch",
  async (req) => {
    return req;
  }
);

export const updateDashboardWard = createAsyncThunk(
  "updateDashboardWard",
  async (req) => {
    return req;
  }
);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
} from "@mui/material";

import "../styles/DataEntry.scss";

import {
  babyCnsAddition,
  updatePatientFormData,
  setBabyCNSData,
  getBabyCNSDataByReadingId,
  setFormTab,
  getPatientLastReadingData,
  getPatientBasicDetails,
} from "../reducers/patientDataThunk";
import CircularProgress from "@mui/material/CircularProgress";

const BabyCNSFunction = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  let reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
    const ptbmrn = patient.bmrndata?.baby_medical_record_number;
    const [dischargeFlag, setDischargeFlag] = useState("1");
  const loggedUserId = user?.userProfile?.user_id;
  const [addBabyCNSData, setAddBabyCNSData] = useState(patient.babyCNSData);
  const [isLoading, setIsLoading] = useState(true);

  let url = `patient/update/baby_cns`;
  const [updateRecord, setUpdateRecord] = useState();
  //const [isValid, setValid] = React.useState(true);
  /*  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  }; */
  useEffect(() => {
   
    const req = { baby_medical_record_number: ptbmrn };

    dispatch(getPatientBasicDetails(req)).unwrap()
    .then((resdata) => {
      const disFlag = resdata.response.discharge_flag;
      setDischargeFlag(disFlag);
    });
}, [ptbmrn]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setAddBabyCNSData(patient.babyCNSData);
  }, [patient.babyCNSData]);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };
    dispatch(getBabyCNSDataByReadingId(req))
      .unwrap()
      .then((resdata) => {
        
        if(resdata.response){
        let CNSFData = {
          response: Object.assign({}, resdata.response),
        };
        const data =
          CNSFData.response && CNSFData.response
            ? CNSFData.response
            : undefined;
        const data1 =
          CNSFData.response && CNSFData.response.id
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        // console.log('getBabyCNSDataByReadingId:',resdata.response);
        if (resdata.response) {
          setAddBabyCNSData(
            CNSFData.response && CNSFData.response ? CNSFData.response : {}
          );
        }
      }
      else{
        const study_id = patient?.bmrndata?.study_id;
    dispatch(getPatientLastReadingData(study_id)) 
    .unwrap()
          .then((resdata) => {
            if(resdata.response){
            let CNSFData = {
              response: Object.assign({}, resdata.response.baby_cns),
            };
            const data =
              CNSFData.response && CNSFData.response
                ? CNSFData.response
                : undefined;
            const data1 =
              CNSFData.response && CNSFData.response.id
                ? setUpdateRecord(true)
                : setUpdateRecord(false);
            // console.log('getBabyCNSDataByReadingId:',resdata.response);
            if (resdata.response) {
              setAddBabyCNSData(
                CNSFData.response && CNSFData.response ? CNSFData.response : {}
              );
            }
          }
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...addBabyCNSData };
    newFormData[fieldname] = fieldvalue;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddBabyCNSData(newFormData);
    setBabyCNSData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(7);
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addBabyCNSData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    if (!updateRecord) {
      dispatch(babyCnsAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyCNSDataByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // console.log("Inside baby cns update");
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyCNSDataByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="PatientRecordID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Record Id
                  </label>
                  <TextField
                    id="study_id"
                    name="study_id"
                    label=""
                    variant="outlined"
                    value={patient.bmrndata?.study_id}
                    onChange={handleAddFormChange}
                    className="dataentry_input"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="FeaturesOfEncephalopathy"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Features of Encephalopathy
                  </label>
                  <Select
                    labelId="Features of Encephalopathy"
                    id="features_of_encephalopathy"
                    name="features_of_encephalopathy"
                    label=""
                    onChange={handleAddFormChange}
                    size="small"
                    className="dataentry_select_edu"
                    value={addBabyCNSData?.features_of_encephalopathy}
                    disabled={dischargeFlag=="0"}
                  >
                    <MenuItem value="Lethargy">Lethargy</MenuItem>
                    <MenuItem value="Stupor">Stupor</MenuItem>
                    <MenuItem value="Coma">Coma</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="NA">NA</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Seizures"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Seizures
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyCNSData?.seizures}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="seizures"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="seizures"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="seizures"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AbnormalMovementsLikeTonicPosturing"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Abnormal Movements like Tonic Posturing
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    value={
                      addBabyCNSData?.abnormal_movements_like_tonic_posturing
                    }
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="abnormal_movements_like_tonic_posturing"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="abnormal_movements_like_tonic_posturing"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="abnormal_movements_like_tonic_posturing"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AFBulge"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    AF Bulge
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyCNSData?.af_bulge}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="af_bulge"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="af_bulge"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="af_bulge"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="BabyMovementWithStimulation"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Baby Movement with Stimulation
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyCNSData?.baby_movement}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag=="0"}
                  >
                    <ToggleButton
                      name="baby_movement"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      name="baby_movement"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      No
                    </ToggleButton>
                    <ToggleButton
                      name="baby_movement"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {/*  {isValid ? ( */}
                <Button
                  variant="outlined"
                  color="primary"
                  aria-label="add"
                  className="buttonStyle sign-up-button"
                  type="submit"
                  disabled={dischargeFlag=="0"}
                >
                  Save {"&"} Next
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabyCNSFunction;
